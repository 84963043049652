.priceWrap {
  border: solid 1px #d7dde2;
  border-radius: 4px;
  padding: 15px 0 10px;
}

.price {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.buttonGo {
  display: block;
  width: 80%;
  margin: 10px auto;
  text-align: center;
  background: #ff6542;
  padding: 10px 5px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #ff481f;
  }
}

.buttonGoText {
  flex: auto;
  color: #fff;
}

.socials {
  width: 100%;
  margin-top: 30px;
  text-align: center;

  img {
    width: 30px;
    margin: 0 5px;
  }
}
