.root {
  text-align: center;

  input[type='file'] {
    display: none;
  }
}

.item {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;

  img {
    height: 100px;
  }

  svg {
    cursor: pointer;
  }
}

.fileUpload {
  svg {
    height: 64px;
    width: 64px;
    cursor: pointer;
  }
}
