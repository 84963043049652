@import 'src/css/variables';

.root {
  margin-bottom: 10px;

  input {
    position: relative;
    width: calc(95% - 20px);
    padding: 0 10px;
    border-radius: 3px;
    border: solid 1px $input-border-color;
    outline: none;
    height: 30px;
  }
}

.error {
  input,
  textarea {
    border-color: $error-color;
    margin-bottom: 0;
  }
}

.errorText {
  color: $error-color;
  font-size: 12px;
  text-align: left;
  padding-left: 4px;
}
