@import 'src/css/variables';

@mixin button {
  color: white;
  display: inline-block;
  text-align: center;
  background: $primary-color;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: darken($primary-color, 5%);
  }

  &:active {
    background: darken($primary-color, 10%);
  }
}
