.modal {
  position: absolute;
  right: 5%;
  top: 90px;
  opacity: 1;
  border: solid 1px #eee;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background: #ff6542;
  max-width: 220px;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 3px;
  padding: 30px 30px;
  text-align: center;
  z-index: 90;
  display: none;
}

.open {
  display: block;
}
