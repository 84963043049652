.dropdown {
  font-size: 0.8rem;
}

.dropdownLable {
  height: 35px;
  width: auto;
  border: solid 1px #ddd;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .dropdown {
    display: none;
  }
}
