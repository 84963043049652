.dropdown {
  display: flex;
  list-style: none;

  li {
    white-space: nowrap;

    &:hover ul {
      top: 0;
      left: 0;
      opacity: 1;
    }
  }

  ul {
    list-style: none;
    position: absolute;
    left: -100000px;
    opacity: 0;
    border: solid 1px #ddd;
    border-radius: 3px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
    background: #fff;
    z-index: 10;

    ul {
      width: 200px;
    }
  }

  ul::before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 30px;
    background: transparent;
    cursor: pointer;
  }

  ul li a {
    text-decoration: none;
    padding: 10px 20px;
    white-space: nowrap;
    display: block;
  }

  li:hover ul li a {
    text-decoration: none;
  }

  li:hover ul li a:hover {
    background: #eee;
  }

  img {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 4px;
    margin-right: 2px;
    height: 16px;
  }
}
