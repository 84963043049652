.root {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.inputBox {
  display: flex;
  gap: 2px;
  align-items: center;
}

.menu {
  min-width: 100%;
}
