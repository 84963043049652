.item {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.imageWrap {
  width: 64px;
  height: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
