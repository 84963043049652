.input {
  width: 100%;
  border: none;
  outline: none;
}

.dropdownLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  padding: 0 10px;
  border-radius: 3px;
  border: solid 1px #ccc;
  outline: none;
  height: 30px;
}
