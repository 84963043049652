@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

$base-color: #677483;
$primary-color: #ff6542;
$border-color: #d7dde2;
$disabled-color: #dddd;

/* ==== UNIVERSAL ==== */
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

body {
  overflow-y: scroll !important;
}

/* ==== ROOT FONT STYLES ==== */

* {
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  color: #3c3c3b;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

/* ==== TYPOGRAPHY ==== */

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.8;
  color: $base-color;
}

/* ==== FONT WEIGHT ==== */

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

/* ==== POSITIONING ==== */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/* ==== CUSTOM STYLES ==== */

header {
  height: auto;
}
.header-wrap {
  display: flex;
}
.header-logo {
  height: 60px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  order: 1;
}
.header-logo img {
  height: 35px;
}
.header-search {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  order: 2;
}
.search-bar {
  height: 35px;
  padding: 0 0 0 20px;
  border: solid 1px $border-color;
  border-right: 0;
  outline: none;
  flex: auto;
}
.category-dropdown {
  height: 35px;
  padding: 0 10px;
  border: solid 1px $border-color;
  border-right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 0.8rem;
  background: #f8f8fc;
  display: flex;
  align-items: center;
}

.search-button {
  position: relative;
  height: 35px;
  width: 45px;
  border: solid 1px $border-color;
  border-left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.search-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../assets/img/search.svg') no-repeat center;
  background-size: cover;
  width: 20px;
  height: 20px;
}

.header-dropdowns {
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 50px;
  order: 3;
}
.header-dropdowns-dropdown {
  font-size: 0.8rem;
  margin: 0 20px;
}
.header-dropdowns-dropdown:last-child {
  margin: 0 0 0 20px;
}

article {
  padding: 10px 0 10px;
}
.article-title {
  font-size: 1.5rem;
  font-weight: 500;
}
.sort-button {
  position: relative;
  padding: 5px 10px 5px 34px;
  display: inline;
  cursor: pointer;
  border-radius: 6px;
}
.sort-button:hover {
  box-shadow: 0 0 0 1px #d7dde2;
}
.sort-button img {
  position: absolute;
  margin-left: -23px;
  top: 50%;
  transform: translate(0, -50%);
  width: 14px;
}
.sort-button span {
  font-size: 0.9rem;
  line-height: 2.3rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.breadcrumb li {
  font-size: 0.8rem;
}
.breadcrumb li::before {
  content: '/';
  padding: 0 10px;
  color: #999;
}
.breadcrumb li:first-child::before {
  content: '';
  padding: 0 0;
}
.breadcrumb a {
  text-decoration: none;
}

.category-title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 0 30px 0;
}

.category-link {
  text-decoration: none;
}

.category-img {
  width: 50px;
}
.category-text {
  font-size: 0.8rem;
  font-weight: 500;
}

.padding-5 {
  padding: 5px 0;
}
.padding-20 {
  padding: 20px 0;
}
.card {
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border: solid 1px #d7dde2;
  cursor: pointer;
  border-radius: 6px;
}
.card:hover {
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); */
}
.card img {
  position: relative;
  display: block;
  width: 100%;
}
.card-image {
  width: 100%;
  height: 200px;
  background-color: #d5d5d5;
  background-size: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.card-wrap {
  position: relative;
  padding: 20px 20px;
  border-top: 0;
}
.card-title {
  font-size: 1rem;
  font-weight: 500;
  height: 48px;
  overflow: hidden;
}
.card-price-wrap {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.card-price {
  flex: auto;
  font-size: 1.2rem;
  font-weight: 500;
  color: $primary-color;
}
.card-no-price {
  flex: auto;
}
.card-arrow img {
  width: 20px;
  transition: all ease 0.1s;
}
.card:hover .card-arrow img {
  margin-right: -5px;
}

.filter-wrap {
  border: solid 1px #dbe2e7;
  border-radius: 6px;
  padding: 20px 20px;

  ul li {
    position: relative;
    list-style: none;
    margin-left: -10px;
    padding: 5px 0;
    font-size: 0.8rem;
  }

  a {
    text-decoration: none;
  }
}

.filter-title {
  font-size: 0.8rem;
  font-weight: 500;
}

.characteristics {
  width: 100%;
  border: 0;
  border-spacing: 0px;
}
.characteristics tr {
  border: 0;
}
.characteristics td {
  color: #677483;
  border-bottom: solid 1px $border-color;
  padding: 10px 0;
  font-size: 0.9rem;
}

.price {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.button-go {
  display: block;
  width: 80%;
  margin: 10px auto;
  text-align: center;
  color: #fff;
  background: #363e49;
  padding: 10px 5px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
}
.button-go-text {
  flex: auto;
  color: #fff;
}

.socials {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}
.socials img {
  width: 30px;
  margin: 0 5px;
}

.mobile-only {
  display: none;
}

footer {
  padding: 20px 0;
  margin-top: 20px;
  text-align: center;
  background: #f8f8fc;
}
.footer-text {
  font-size: 0.7rem;
  color: #abb2bf;
  font-weight: 400;
}

input[type='checkbox']:disabled + label {
  color: $disabled-color;
}

.checkbox-list {
  max-height: 260px;
  overflow: auto;
}
