/* ==== @MEDIA ==== */

@media only screen and (max-width: 720px) {
  .container {
    width: 90%;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 96%;
  }
  .hidden-sm {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .header-logo {
    height: 50px;
    margin-right: 20px;
    order: 1;
  }
  .header-logo img {
    height: 28px;
  }
  .header-search {
    height: 50px;
    width: 100%;
    order: 3;
  }
  .header_search_dropdown {
    display: none;
  }
  .search-bar {
    padding: 0 0 0 10px;
    height: 30px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .search-button {
    height: 30px;
    width: 35px;
  }
  .login_button {
    margin-left: 20px;
  }
  .login_button span {
    display: none;
  }
  .login_button img {
    height: 30px;
    margin-right: 5px;
  }
  .category-img {
    width: 30px;
  }
  .category-text {
    font-size: 0.5rem;
    font-weight: 500;
  }
}
