.slider {
  position: relative;
}

.imgWrapper {
  position: relative;
  overflow: hidden;
}

.item {
  display: none;
  animation: reveal 0.5s ease-in-out;
  height: 480px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(13px);
  opacity: 0.4;
}

.active {
  display: block;
}

.dots {
  text-align: center;
  padding: 10px;

  li {
    cursor: pointer;
    display: inline-block;
    background: #333;
    color: #fff;
    padding: 3px 7px;
    line-height: 0.5;
    border-radius: 50%;
    text-indent: -9999px;
    opacity: 0.5;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    margin: 0 2px;
  }

  .dotsActive {
    opacity: 1;
  }
}

.prevBtn,
.nextBtn {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20%;
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 36px;
  font-weight: 200;
  transition: all 0.4s ease;

  svg {
    fill: #fff;
  }

  &:hover {
    background: rgba(white, 0.5);
    box-shadow: 0 0 15px rgba(black, 0.1);
  }
}

.nextBtn {
  right: 0;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
