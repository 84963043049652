.pre_header_dropdown li {
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: 500;
}
.pre_header_dropdown li:hover ul {
  top: 33px;
}
.pre_header_dropdown_title {
  line-height: 26px;
  padding: 0 30px 0 15px;
  cursor: pointer;
}
.pre_header_dropdown_title::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  background: url('../assets/img/caret-down.svg') no-repeat center;
  background-size: cover;
  width: 10px;
  height: 10px;
}
.currency_label {
  position: relative;
  display: inline-block;
  background: #ff6542;
  color: #fff;
  padding: 0 6px;
  font-size: 11px;
  font-weight: normal;
  line-height: 12px;
  border-radius: 2px;
  margin-right: 2px;
}
.language_label {
  position: relative;
  top: 2px;
  display: inline-block;
  background: #ff6542;
  color: #fff;
  width: 18px;
  height: 12px;
  border-radius: 2px;
  margin-right: 2px;
}
.rus {
  background: url('../assets/img/flags/rub.png') no-repeat center;
  background-size: cover;
}
.english {
  background: url('../assets/img/flags/gbp.png') no-repeat center;
  background-size: cover;
}
.turkish {
  background: url('../assets/img/flags/try.png') no-repeat center;
  background-size: cover;
}

header {
  height: auto;
  border-bottom: solid 1px #eee;
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); */
  background: #fff;
  display: flex;
  align-items: center;
}
.header-wrap {
  display: flex;
  align-items: center;
}
.header-logo {
  position: relative;
  top: 1px;
  height: 60px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  order: 1;
}
.header-logo img {
  height: 35px;
}
.header-search {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  order: 2;
}
.search-bar {
  background: #fff;
  width: calc(100% - 115px);
  height: 35px;
  padding: 0 0 0 20px;
  border: solid 1px #ddd;
  border-right: 0;
  outline: none;
  flex: auto;
}
.header_search_dropdown {
  height: 35px;
  width: auto;
  border: solid 1px #ddd;
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 0.8rem;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.header_search_dropdown li {
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: 500;
}
.header_search_dropdown_title {
  line-height: 1;
  padding: 15px 30px 15px 15px;
  cursor: pointer;
}

.header_search_dropdown li:hover ul {
  top: 45px;
  right: 0px;
  opacity: 1;
}

.search-button {
  position: relative;
  height: 35px;
  width: 45px;
  border: solid 1px #ddd;
  background: #fff;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}
.search-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../assets/img/search.svg') no-repeat center;
  background-size: cover;
  width: 20px;
  height: 20px;
}
.login_button {
  border: none;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 50px;
  order: 3;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  gap: 5px;
}
.login_button span {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}
.login_button img {
  height: 30px;
  margin-right: 5px;
}

#login {
  position: absolute;
  right: 5%;
  opacity: 1;
  border: solid 1px #eee;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background: #ff6542;
  max-width: 220px;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 3px;
  padding: 30px 30px;
  text-align: center;
  z-index: 90;
  display: none;
}
.login_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}
.login_form {
  position: relative;
  width: 100%;
  position: relative;
}
.login_input_text {
  position: relative;
  width: calc(95% - 20px);
  padding: 0 10px;
  border-radius: 3px;
  border: solid 1px #ccc;
  margin-bottom: 10px;
  outline: none;
  height: 30px;
}
.login_submit {
  background: #ff6542;
  border: 0;
  color: #fff;
  padding: 0 15px;
  height: 30px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  line-height: 1;
}
.forgot_button {
  position: absolute;
  top: 48px;
  right: 10px;
  width: auto;
  height: 20px;
  line-height: 20px;
  background: #eee;
  font-size: 10px;
  font-weight: 500;
  color: #555;
  border-radius: 3px;
  padding: 0 5px;
  cursor: pointer;
}
.forgot_button:hover {
  background: #ddd;
}
