.block {
  width: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: solid 1px #d7dde2;
  border-radius: 3px;
  padding: 10px 10px;
}

.linkWrap {
  width: auto;
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.img {
  margin: 0 auto;
  width: 30px;
  margin-right: 5px;
}

.text {
  font-size: 0.8rem;
  font-weight: 500;
}
