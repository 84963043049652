.root {
  width: 80%;
  margin: 20px auto;
}

.item {
  display: flex;
  gap: 10px;
  align-items: center;
}
