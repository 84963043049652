/*  */
/* Filter_wrap styles */
/*  */
.wrap {
  border: solid 1px #dbe2e7;
  border-radius: 4px;
  padding: 20px 20px;

  ul li {
    position: relative;
    list-style: none;
    margin: 5px 20px;
    padding: 5px 0;
    font-size: 0.8rem;
  }
}
