.registerLinkWrap {
  margin-top: 10px;
  font-size: 12px;
}

.personIcon {
  width: 30px;
  height: 30px;
}

.exitIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
