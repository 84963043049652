.title {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 5px 0;
}

.checkboxList {
  max-height: 260px;
  overflow: auto;

  li {
    display: flex;
    gap: 4px;
  }
}
