@import 'src/css/variables';

.preHeader {
  position: relative;
  margin: 0;
  padding: 5px 0;
  width: 100%;
  background: #f3f3f3;
  border-bottom: solid 1px #eee;
  z-index: 100;
}

.preHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: end;
}

.addButton {
  display: block;
  background: $primary-color;
  border: 0;
  color: $light-text-color;
  padding: 0 10px;

  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 20px;

  &:hover {
    background: darken($primary-color, 5%);
  }

  &:active {
    background: darken($primary-color, 10%);
  }
}

.headerWrap {
  position: sticky;
  top: 0;
  z-index: 1;
}
