@import 'src/css/variables';

.root {
  color: $error-color;
  background-color: lighten($error-color, 60%);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 12px;
}
