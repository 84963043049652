@import 'src/css/mixins';

.root {
  text-align: center;
  margin: 200px auto;
}

.button {
  @include button;
}
