.menu {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  z-index: 100;
  margin: 0;
  width: fit-content;
  text-align: left;
  white-space: nowrap;
  max-height: 300px;
  overflow-y: auto;

  li {
    list-style-type: none;
    padding: 0;
    padding: 10px 20px;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: #eee;
    }
  }
}
