.priceDropdown {
  font-size: 12px;
}

.flexWrap {
  display: flex;
  gap: 10px;
}

.textarea {
  position: relative;
  width: calc(95% - 15px);
  resize: none;
  padding: 10px 15px;
  border-radius: 3px;
  border: solid 1px #ccc;
  margin-bottom: 10px;
  outline: none;
  height: 50px;
  background: #fff;
  font-weight: 500;
  font-size: 12px;
}
