@import 'src/css/variables';

.root {
  background: $primary-color;
  border: 0;
  color: $light-text-color;
  padding: 0 15px;
  height: 30px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  line-height: 1;

  &:hover {
    background: darken($primary-color, 5%);
  }

  &:active {
    background: darken($primary-color, 10%);
  }
}
